import { hydrateRoot } from 'react-dom/client';
import { RemixBrowser } from '@remix-run/react';
import { initAmplitude } from './lib/amplitude.client';
import { initSentry } from './lib/sentry.client';

const userId = window.env.USER_ID;
const userEmail = window.env.USER_EMAIL;

initSentry(userId, userEmail);
initAmplitude();

hydrateRoot(document, <RemixBrowser />);
